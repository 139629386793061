import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const SubstitutePage = () => {
  return (
    <Layout title="A Substitute for Life">
        <h2>Scenario</h2>
        <blockquote>...a bleak reality where we replace bits of ourselves with smart-tech augmentations so that we can perform better in an increasingly corporate world, all the while adapting our appearances to a beautiful but bland sameness as promoted by social media with its pervasive filters [...] and is this [...] the sleepless world our ancestors dreamed for us? Or is this only a substitute for life?</blockquote>
        <center><Image src="subforlife-1.png" alt="Drawing" /></center>
        <p>&nbsp;</p>
        <center><Image src="subforlife-2.png" alt="Drawing" /></center>
        <center><Image src="subforlife-3.png" alt="Drawing" /></center>
        <center><Image src="subforlife-4.png" alt="Drawing" /></center>
        <center><Image src="subforlife-5.png" alt="Drawing" /></center>
        <center><Image src="subforlife-6.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default SubstitutePage